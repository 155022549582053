.services-we-offer-container{
    margin: 3rem 3rem 3rem 4rem;
    h1{
        margin: 0;
        font-size: 2.5rem;
        font-weight: 700;
        margin-bottom: 1.5rem;
        color: #1090CB;
    }

    .services-we-offer-section{
        display: flex;
        align-items: start;

        .services-we-offer-container-left{
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            // align-items: center;
            p{
                margin: 0;
                color: #585858 ;
                font-size: 1.4rem;
                line-height: 1.8;
            }
            .btns-wrapper{
                display: flex;
                margin-top: 2rem;
                .btn{
                    background-color: #1090CB;
                    color: white;
                    padding: .8rem 1.5rem;
                    border: none;
                    border-radius: 5px;
                    margin-right: 1rem;
                    cursor: pointer;
                    transition: all 0.3s;
                    &:hover{
                        background-color: #0C5A7C;
                    }
                    &:last-child{
                        border: 1px solid #1090CB;
                        background-color: white;
                        color: #1090CB;
                    }
                }
            }
        }
        .services-we-offer-container-right{
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: white;
            border-radius: 10px;
            box-shadow: 0px 0px 10px 0px #0000001a;
            .services-we-offer-container-right-layer1, .services-we-offer-container-right-layer2{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                padding: 1rem;
                .service{
                    display: flex;
                    gap:1rem;
                    justify-content: center;
                    align-items: center;
                    margin: 1rem;
                    img{
                        width: 60px;
                        height: 60px;
                    }
                    p{
                        margin: 0;
                        font-size: 1.4rem;
                        font-weight: 500;
                        color: #2E0D64;
                    }
                }
            }
            
            img{
                width: 50px;
                height: 50px;
            }
            
        }
    }
}