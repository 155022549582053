@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.hero-section{
    position: relative;
    padding: 3rem 4.5rem;
    .hero-section-layer1{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .hero-section-layer1-right   
        {
            display: flex;
            align-items: end;
            gap:2rem;
            img{
                width: 40%;
            }
            
        }
        .hero-section-layer1-left{
            width: 50%;
            h3{
                margin: 0;
                color: #1090CB;
                font-weight: 500;
                font-family: 'Poppins', sans-serif;
            }
            p{
                text-align: justify;
                font-size: 18px;
                color: #868686;
                font-weight: 400;
                font-family: 'Poppins', sans-serif;
            }
        }   
    }
    .hero-section-layer2{
        display: flex;
        gap: 2rem;
        margin: 1rem 3rem;
        img{
            width: 49%;
        }
       img:last-child{
        // visibility: hidden;
        position: relative;

        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top:150%;
            right:-50%;
            background-color: #1090CB;
            opacity: 0.5;
            background-image: url('../../assets/aboutUS//design-dots.svg');
        }
       }
    }
    .design-blue{
        width: 50px;
        position: absolute;
        top: 40%;
        left: 0;
        z-index: -1;
    }
    .design-green{
        position: absolute;
        top: -15%;
        right: 0;
        width: 50px;
        z-index: -1;
    }
}