.footer {
    // margin-top: 1rem;
    background-color: #1090CB;
    color: black;
    padding: 20px 0;
    text-align: center;
    bottom: 0px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 600px) {
        flex-direction: row;
        justify-content: space-between;
        padding: 0 20px;
    }
}

.links {
    margin-bottom: 10px;

    @media (min-width: 600px) {
        margin-bottom: 0;
    }

    .link {
        color: black;
        margin: 0 15px;
        text-decoration: none;
        transition: color 0.3s;

        &:hover {
            color: white; // Change color on hover
        }
    }
}

.socialMedia {
    .socialLink {
        color: black;
        margin: 0 10px;
        text-decoration: none;
        transition: color 0.3s;

        &:hover {
            color: white; // Change color on hover
        }
    }
}

.copyRight {
    margin-top: 10px;
    font-size: 14px;
}
