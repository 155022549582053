.why-choose-us-section{
    margin: 1rem 3rem 1rem 4rem;
    h1{
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 1rem;
        color: #1090CB;
    }
    p{
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 2;
        color: #585858;
    }
}